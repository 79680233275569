import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Input, } from "antd"

import AppButton from "../AppButton"
import {SearchOutlined} from "@ant-design/icons";

// ----------------------------------------------------------------------------

function TableSearchPopover(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { inputRef, value, onChange, onPressEnter, onSearch, onReset } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <Input
        ref={(ref) => {
          if (inputRef) {
            inputRef.current = ref
          }
        }}
        placeholder={`Cerca`}
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
      />

      <div className="row">
        <AppButton className="search-btn" onClick={onSearch} icon="search">
            <SearchOutlined style={{ color: "#FFFFFF" }}/>
            <span>Cerca</span>
        </AppButton>

        <AppButton onClick={onReset} style="ghosted">
          Reset
        </AppButton>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

TableSearchPopover.propTypes = {
  className: PropTypes.string.isRequired,
  inputRef: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
}

TableSearchPopover.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledTableSearchPopover = styled(TableSearchPopover)`
  & {
    display: flex;
    flex-direction: column;

    padding: 20px;
    width: auto;

    .ant-input {
      margin-bottom: 10px;
      height: 36px;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .search-btn {
      margin-right: 10px;

      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      .anticon {
        margin-right: 5px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledTableSearchPopover
