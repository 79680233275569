import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Popover, message } from "antd"

import TextInput from "../../../../_shared/components/FormItems/AppInput"
import UOMInput from "../../../../_shared/components/FormItems/UOMInput"

import AppSwitch from "../../../../_shared/components/FormItems/AppSwitch"
import AppButton from "../../../../_shared/components/AppButton"
import NewTokensForm from "../../../../_shared/components/NewTokensForm"

// ----------------------------------------------------------------------------

function CreateCompanyForm(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onSubmit, company, onCreateNewTokens} = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [email, setEmail] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [plantCode, setPlantCode] = useState("")
  const [bleDevices, setBleDevices] = useState("")
  const [tokenNumber, setTokenNumber] = useState(0)
  const [multiDeviceWaitTime, setMultiDeviceWaitTime] = useState(0)
  const [automaticSendCode, setAutomaticSendCode] = useState(false)
  const [nfcCapabilityEnabled, setNfcCapabilityEnabled] = useState(true)
  const [reasonCapabilityEnabled, setReasonCapabilityEnabled] = useState(false)
  const [presenceCapabilityEnabled, setPresenceCapabilityEnabled] = useState(
    true
  )
  const [accessCapabilityEnabled, setAccessCapabilityEnabled] = useState(true)
  const [terminalResponseWaitTime, setTerminalResponseWaitTime] = useState(1)
  // const [resetPassword, setResetPassword] = useState("")
  // const [resetPasswordConfirm, setResetPasswordConfirm] = useState("")

  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (company && company.id) {
      setEmail(company.email)
      setCompanyName(company.companyName)
      setPlantCode(company.plantCode)
      setBleDevices(company.bleDevices)
      setMultiDeviceWaitTime(company.multiDeviceWaitTime)
      setAutomaticSendCode(company.automaticSendCode)
      setNfcCapabilityEnabled(company.nfcCapabilityEnabled)
      setReasonCapabilityEnabled(company.reasonCapabilityEnabled)
      setPresenceCapabilityEnabled(company.presenceCapabilityEnabled)
      setAccessCapabilityEnabled(company.accessCapabilityEnabled)
      setTerminalResponseWaitTime(company.terminalResponseWaitTime)
      // setResetPassword("")
      // setResetPasswordConfirm("")
    } else {
      clearState()
    }
  }, [company])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleSubmit(event) {
    event.preventDefault()

    let body = {
      email,
      companyName,
      tokenNumber,
      plantCode,
      bleDevices,
      multiDeviceWaitTime,
      automaticSendCode,
      nfcCapabilityEnabled,
      reasonCapabilityEnabled,
      presenceCapabilityEnabled,
      accessCapabilityEnabled,
    }

    try {
      onSubmit && (await onSubmit(body))

      clearState()

      //eslint-disable-next-line
    } catch (err) {}
  }

  function handleCreateNewTokens(number) {
    if (number) {
      onCreateNewTokens && onCreateNewTokens(company.id, number)

      setIsPopoverVisible(false)
    }
  }

  function handlePopoverVisibilityChange(visible) {
    setIsPopoverVisible(visible)
  }

  function clearState() {
    setEmail("")
    setCompanyName("")
    setPlantCode("")
    setBleDevices("")
    setTokenNumber(0)
    setMultiDeviceWaitTime(0)
    setAutomaticSendCode(false)
    setReasonCapabilityEnabled(true)
    setNfcCapabilityEnabled(true)
    setPresenceCapabilityEnabled(true)
    setAccessCapabilityEnabled(true)
    setTerminalResponseWaitTime(1)
  }

  function isEditMode() {
    return company && company.id
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <form className={props.className} onSubmit={handleSubmit}>
      <h1>{`${company && company.id ? "Modifica" : "Crea una nuova azienda"}`}</h1>

      <div className="form-content-container">
        <h4>{`Dati dell'azienda`}</h4>
        <TextInput
          className="form-input"
          placeholder="Email"
          value={email}
          onChange={setEmail}
        />

        <TextInput
          className="form-input"
          placeholder="Ragione sociale"
          value={companyName}
          onChange={setCompanyName}
        />
        <TextInput
          className="form-input"
          placeholder="Codice impianto"
          value={plantCode}
          onChange={setPlantCode}
        />
        <TextInput
          className="form-input"
          placeholder="Dispositivi BLE"
          value={bleDevices}
          onChange={setBleDevices}
        />

        {(!company || !company.id) && (
          <TextInput
            className="form-input"
            placeholder="Numero badge disponibili"
            value={tokenNumber}
            onChange={setTokenNumber}
          />
        )}

        <h4>{`Impostazioni dell'applicazione`}</h4>

        <UOMInput
          className="form-input"
          placeholder="Tempo risposta terminale"
          value={terminalResponseWaitTime}
          onChange={setTerminalResponseWaitTime}
          unitOfMeasurement={"s"}
        />

        <UOMInput
          className="form-input"
          placeholder="Tempo attesa multi device"
          value={multiDeviceWaitTime}
          onChange={setMultiDeviceWaitTime}
          unitOfMeasurement={"s"}
        />
        <AppSwitch
          className="form-switch"
          placeholder="Abilita terminali controllo presenze"
          value={presenceCapabilityEnabled}
          onChange={setPresenceCapabilityEnabled}
        />
        <AppSwitch
          className="form-switch"
          placeholder="Abilita terminali entrata/uscita"
          value={accessCapabilityEnabled}
          onChange={setAccessCapabilityEnabled}
        />
        <AppSwitch
          className="form-switch"
          placeholder="Abilita terminali NFC"
          value={nfcCapabilityEnabled}
          onChange={setNfcCapabilityEnabled}
        />
        <AppSwitch
          className="form-switch"
          placeholder="Abilita causali"
          value={reasonCapabilityEnabled}
          onChange={setReasonCapabilityEnabled}
        />
        <AppSwitch
          className="form-switch"
          placeholder="Invio codice automatico"
          value={automaticSendCode}
          onChange={setAutomaticSendCode}
        />
      </div>

      <div className="btns-container">
        {isEditMode() && (
          <Popover
            content={
              <NewTokensForm
                onSubmit={handleCreateNewTokens}
                visible={isPopoverVisible}
                inputPlaceholder="Numero badge da assegnare"
              />
            }
            title="Assegna nuovi badge all'utente"
            trigger="click"
            visible={isPopoverVisible}
            onVisibleChange={handlePopoverVisibilityChange}
          >
            <AppButton
              type="button"
              style="ghosted"
              className="margined-btn new-tokens-btn"
            >
              Assegna nuovi badge
            </AppButton>
          </Popover>
        )}

        <AppButton className="form-btn">{`${
            company && company.id ? "Modifica" : "Crea"
        }`}</AppButton>
      </div>
    </form>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CreateCompanyForm.propTypes = {
  className: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCreateNewTokens: PropTypes.func,
  company: PropTypes.object,
}

CreateCompanyForm.defaultProps = {
  onSubmit: () => {},
  onCreateNewTokens: () => {},
}

// ----------------------------------------------------------------------------

const StyledCreateCompanyForm = styled(CreateCompanyForm)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 50px;
    box-sizing: border-box;

    height: 100%;
    width: 100%;

    > h1 {
      font-weight: bold;
    }

    .form-content-container {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      > h4 {
        padding: 3px 0;

        border-bottom: 1px solid ${(props) => props.theme.UITheme.textLight};
        font-weight: 600;
      }

      .form-switch {
        margin-top: 5px;
      }

      .form-input {
        margin-bottom: 10px;
      }
    }

    .btns-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      margin-top: 25px;
      padding: 20px 10px;
      width: 100%;

      background-color: ${(props) => props.theme.UITheme.dividerColor};

      box-sizing: border-box;

      width: 100%;

      .margined-btn {
        margin-right: 10px;
      }

      .disable-btn {
        margin-right: 10px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledCreateCompanyForm
