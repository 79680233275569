import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Layout, Dropdown, Menu } from "antd"

import ROLES from "../../../_shared/constants/roles.js"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import {ArrowDownOutlined} from "@ant-design/icons";

// ----------------------------------------------------------------------------

const { Header } = Layout

//------------------------------------------------------------------------------

function AppHeader(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [{ user, company }, dispatch] = useStateValue()
  const containerRef = useRef(null)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleSignOut() {
    dispatch({
      type: "SIGN_OUT",
    })
  }

  function getDropdownContent() {
    return (
      <Menu>
        {/* <Menu.Item key="account">
          <a href="">Account</a>
        </Menu.Item>
        <Menu.Divider /> */}
        <Menu.Item key="signOut">
          <a onClick={handleSignOut}>Sign Out</a>
        </Menu.Item>
      </Menu>
    )
  }

  function getUserName() {
    switch (user.role) {
      case ROLES.ADMIN: {
        return company?.companyName
      }

      default: {
        return user.name
      }
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <Header className={props.className}>
      <section className="left-section" />
      <section className="right-section" ref={containerRef}>
        <Dropdown
          overlay={getDropdownContent}
          trigger={["click"]}
          getPopupContainer={() => {
            return containerRef && containerRef.current
          }}
        >
          <div className="drowpdown-container">
            <div
              className="avatar"
              style={{ backgroundImage: `url(${user.picture})` }}
            />
            <span>{getUserName()}</span>
            <ArrowDownOutlined />
          </div>
        </Dropdown>
      </section>
    </Header>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppHeader.propTypes = {
  className: PropTypes.string.isRequired,
}

AppHeader.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledAppHeader = styled(AppHeader)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: ${props => props.theme.UITheme.white} !important;

    .left-section {
      flex: 1;
    }

    .right-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${props => props.theme.UITheme.textDark};

      .drowpdown-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;

        > span {
          margin: 0 5px 0 15px;
          user-select: none;
        }

        .avatar {
          width: 32px;
          height: 32px;
          background-size: contain;
          background-origin: content-box;
          border: 1px solid ${props => props.theme.UITheme.textDark};
          border-radius: 50%;
        }
      }

      /* Dropdown style */

      .ant-dropdown {
        ul {
          background-color: ${props =>
            props.theme.UITheme.lighterBackgroundColor};

          li {
            a {
              color: ${props => props.theme.UITheme.textDark};

              :hover {
                text-decoration: none;
                color: ${props => props.theme.UITheme.textLight};
              }
            }

            :hover {
              background-color: ${props =>
                props.theme.UITheme.lighterBackgroundColor};
            }
          }

          .ant-dropdown-menu-item-divider {
            margin: auto;
            width: 90%;
            background-color: ${props => props.theme.UITheme.dividerColor};

            :hover {
              background-color: ${props => props.theme.UITheme.dividerColor};
            }
          }
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAppHeader
