import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { AppStateStore } from "./_shared/context/AppStateStore"

import RoutedApp from "./RoutedApp"

import "./app.css"

// ----------------------------------------------------------------------------

const App = () => {
  const initialState = {
    user: null,
    company: null,
    UITheme: "dark",
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "SIGN_IN_FULFILLED": {
        return {
          ...state,
          token: action.payload.token,
          user: action.payload.user,
        }
      }

      case "SET_COMPANY": {
        return {
          ...state,
          company: action.payload,
        }
      }

      case "SIGN_OUT": {
        return {
          ...state,
          user: null,
          company: null,
          token: null,
        }
      }

      default: {
        return {
          ...state,
        }
      }
    }
  }

  const appTheme = {
    screenSizes: {
      mobile: 850,
    },

    UITheme: {
      accentColor: "#313d4f",
      accentLighterColor: "#7f8fa4",
      primaryColor: "#0047bb",
      darkBackgroundColor: "#f3f3f3",
      lightGrey: "##d8d8d8",
      backgroundColor: "#DBE7EE",
      textColor: "#333333",
      accentTextColor: "#313d4f",
      textLight: "#7f8fa4",
      dividerColor: "#DBE7EE",
      backgroundGradient: "180deg, #1f2935 0%, #425365 100%",
      shadowColor: "#DBE7EE",
      warningDarkColor: "#ff453a",
      warningLightColor: "#ff4530",
      white: "#FFFFFF",
    },
  }

  return (
    <AppStateStore
      initialState={initialState}
      reducer={reducer}
      storeKey="sintesi-access-control"
    >
      <ThemeProvider theme={appTheme}>
        <Router>
          <Route path="/" component={RoutedApp} />
        </Router>
      </ThemeProvider>
    </AppStateStore>
  )
}

export default App
