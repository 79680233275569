import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message } from "antd"
import { NavLink } from "react-router-dom"

import * as api from "../../../api"

import { BACKEND_RESPONSES_MESSAGES } from "../../_shared/constants"
import { useStateValue } from "../../_shared/context/AppStateStore"
import AppInput from "../../_shared/components/FormItems/AppInput"
import ROLES from "../../_shared/constants/roles";

//------------------------------------------------------------------------------

function AuthPage(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [, dispatch] = useStateValue()
  const [showPassword, setShowPassword] = useState(false)
  const [showCreatePasswordForm, setShowCreatePasswordForm] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleSignIn(event) {
    event.preventDefault()

    api
      .signIn(email, password)
      .then((res) => {
        dispatch({
          type: "SIGN_IN_FULFILLED",
          payload: res,
        })
        if (res.user.role == ROLES.ADMIN) {
          api.getCompany(res.user.company, res.token).then((company) => {
            dispatch({
              type: "SET_COMPANY",
              payload: company,
            })
          });
        }
      })
      .catch((error) => {
        if (error && error.statusCode === 401) {
          message.error("Le credenziali non sono corrette.")
        } else {
          const defaultMessage =
            "Non è stato possibile completare il login. Riprovare più tardi."

          const messageText = (error && error.serverMessage) || defaultMessage

          message.error(messageText)
        }
      })
  }

  function checkUserEmailStatus(event) {
    event.preventDefault()

    api
      .checkUserEmail(email)
      .then((res) => {
        if (
          res &&
          res.state &&
          BACKEND_RESPONSES_MESSAGES.USER_OK &&
          res.state === BACKEND_RESPONSES_MESSAGES.USER_OK.key
        ) {
          return setShowPassword(true)
        }

        if (
          res &&
          res.state &&
          BACKEND_RESPONSES_MESSAGES.USER_FIRST_ACCESS &&
          res.state === BACKEND_RESPONSES_MESSAGES.USER_FIRST_ACCESS.key
        ) {
          return setShowCreatePasswordForm(true)
        }
      })
      .catch((err) => {
        if (err) {
          const errorMessage =
            (err.serverMessage &&
              BACKEND_RESPONSES_MESSAGES[err.serverMessage] &&
              BACKEND_RESPONSES_MESSAGES[err.serverMessage].message) ||
            "Non è stato possibile completare l'operazione."

          message.error(errorMessage)
        }
      })
  }

  function createAdminPassword(event) {
    event.preventDefault()

    if (showCreatePasswordForm && password !== confirmPassword) {
      return message.error("Le password devono essere uguali.")
    }

    api
      .createAdminPassword(email, password)
      .then((res) => {
        dispatch({
          type: "SIGN_IN_FULFILLED",
          payload: res,
        })
      })
      .catch((err) =>
        message.error(
          "Non è stato possibile configurare la password. Conttatare gli amministratori."
        )
      )
  }

  function getSubmitFunctionBasedOnState() {
    if (!showPassword && !showCreatePasswordForm) {
      return checkUserEmailStatus
    }

    if (showCreatePasswordForm) {
      return createAdminPassword
    }

    if (showPassword) {
      return handleSignIn
    }
  }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <div className="auth-card">
        <div className="message-container">
          <h1>{`Benvenuto nell'admin di BEEID!`}</h1>

          <h4>Inserisci le credenziali per gestire i tuoi accessi.</h4>
        </div>

        <div className="form-container">
          <form onSubmit={getSubmitFunctionBasedOnState()}>
            <h1>Accedi</h1>

            <h4>
              {` Comincia inserendo l'email associata al tuo accout. Verrai guidato nel login.`}
            </h4>

            <AppInput
              className="auth-input"
              placeholder="Email"
              value={email}
              onChange={setEmail}
              autocomplete={false}
            />

            {showPassword && (
              <AppInput
                className={`password-input auth-input ${
                  showPassword ? " visible" : ""
                }`}
                placeholder="Password"
                value={password}
                onChange={setPassword}
                hiddenText
                autocomplete={false}
              />
            )}

            {showCreatePasswordForm && (
              <div className="create-password-container">
                <AppInput
                  className="auth-input"
                  placeholder="Password"
                  value={password}
                  onChange={setPassword}
                  hiddenText
                  autocomplete={false}
                />

                <AppInput
                  className="auth-input"
                  placeholder="Conferma password"
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  hiddenText
                  autocomplete={false}
                />
              </div>
            )}

            <button className="sign-in-btn">Sign In</button>
            <NavLink to="/reset-password" className={"password-recovery"}>
              Password dimenticata?
            </NavLink>
          </form>
        </div>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AuthPage.propTypes = {
  className: PropTypes.string.isRequired,
}

AuthPage.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledAuthPage = styled(AuthPage)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    min-width: 100vw;

    background-color: #161d26;

    background-image: url(${`${require("./images/auth-background-dark.png")}`});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .auth-card {
      position: relative;

      display: flex;
      flex-direction: row;

      width: 80vw;
      height: 75vh;

      max-width: 1128px;
      max-height: 700px;

      background-color: ${(props) => props.theme.UITheme.white};

      border-radius: 8px;

      overflow: hidden;

      .form-container {
        flex: 6;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 120px;

        > form {
          display: flex;
          flex-direction: column;
          align-items: center;

          width: 85%;

          h1 {
            color: ${(props) => props.theme.UITheme.accentTextColor};
            font-weight: 600;
          }

          h4 {
            margin-bottom: 25px;
            max-width: 50%;
            color: ${(props) => props.theme.UITheme.accentLighterColor};
            text-align: center;
            font-weight: 600;
          }

          .create-password-container {
            width: 250px;
          }

          .auth-input {
            margin-bottom: 25px;
          }

          > .sign-in-btn {
            width: 100%;
            max-width: 250px;
            height: 36px;
            border: 1px solid #313d4f;
            border-radius: 4px;
            background-color: #273142;
            color: #ffffff;
            cursor: pointer;

            outline: none;
            transition: all 0.2s ease-in-out;

            :hover {
              border: 1px solid #ffffff;
            }
          }
          .password-recovery {
            margin-top: 30px;
            width: 100%;
            max-width: 250px;
            text-align: right;
            color: ${(props) => props.theme.UITheme.accentTextColor};
          }
        }
      }

      .message-container {
        flex: 4;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 120px;

        background-image: linear-gradient(134deg, #09b66d 0%, #22cce2 100%);

        h1 {
          width: 80%;
          color: ${(props) => props.theme.UITheme.white};
          font-weight: 600;
          text-align: center;
        }

        h4 {
          color: ${(props) => props.theme.UITheme.white};
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledAuthPage
