import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Popover, message } from "antd"

import TextInput from "../../../../_shared/components/FormItems/AppInput"
import UOMInput from "../../../../_shared/components/FormItems/UOMInput"

import AppButton from "../../../../_shared/components/AppButton"
import { Select } from 'antd';
import * as api from "../../../../../api";
import {useStateValue} from "../../../../_shared/context/AppStateStore";

// ----------------------------------------------------------------------------

function CreateUserForm(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onSubmit, user } = props
  const [{ token }] = useStateValue()

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [companyOptions, setCompanyOptions] = useState([])

  const [resetPassword, setResetPassword] = useState("")
  const [resetPasswordConfirm, setResetPasswordConfirm] = useState("")

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    fetchList()
    if (user && user.id) {
      setEmail(user.email)
      setCompany(user.company?._id)
      setName(user.name)
      setSurname(user.surname)
      setResetPassword("")
      setResetPasswordConfirm("")
    } else {
      clearState()
    }
  }, [user])

  function fetchList() {
    api.executeGETRequest("/companies", token).then((res) => {
      const companies =res.rows.map((company) => { return  {label: company.companyName, value: company.id}})
      setCompanyOptions(companies);
    })
  }

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleSubmit(event) {
    event.preventDefault()

    let body = {
      email,
      company,
      name,
      surname,
    }

    if (resetPassword !== resetPasswordConfirm) {
      message.error("Reimposta password e Conferma password devono coincidere")
      return
    }
    if (resetPassword.lenth > 0 && resetPassword.length < 6) {
      message.error("La password deve avere almeno 6 caratteri")
      return
    }

    body.resetPassword = resetPassword
    body.resetPasswordConfirm = resetPasswordConfirm

    try {
      onSubmit && (await onSubmit(body))

      clearState()

      //eslint-disable-next-line
    } catch (err) {}
  }
  function clearState() {
    setEmail("")
    setCompany("")
    setName("")
    setSurname("")
    setResetPassword("")
    setResetPasswordConfirm("")
  }

  function isEditMode() {
    return user && user.id
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <form className={props.className} onSubmit={handleSubmit}>
      <h1>{`${user && user.id ? "Modifica" : "Crea un nuovo utente"}`}</h1>

      <div className="form-content-container">
        <h4>{`Dati del cliente`}</h4>
        <Select
            showSearch
            filterOption={true}
            className="form-input"
            value={company}
            options={companyOptions}
            onChange={setCompany}
        />
        <TextInput
          className="form-input"
          placeholder="Email"
          value={email}
          onChange={setEmail}
        />

        <TextInput
          className="form-input"
          placeholder="Nome"
          value={name}
          onChange={setName}
        />
        <TextInput
          className="form-input"
          placeholder="Cognome"
          value={surname}
          onChange={setSurname}
        />
        {isEditMode() && <h4></h4>}
        {isEditMode() && <h4>{`Reimposta password Admin`}</h4>}
        {isEditMode() && (
          <UOMInput
            className="form-input"
            placeholder="Reimposta password"
            value={resetPassword}
            onChange={setResetPassword}
            hiddenText
            // unitOfMeasurement={"s"}
          />
        )}
        {isEditMode() && (
          <UOMInput
            className="form-input"
            placeholder="Conferma password"
            value={resetPasswordConfirm}
            onChange={setResetPasswordConfirm}
            hiddenText
            // unitOfMeasurement={"s"}
          />
        )}
      </div>

      <div className="btns-container">
        <AppButton className="form-btn">{`${
          user && user.id ? "Modifica" : "Crea"
        }`}</AppButton>
      </div>
    </form>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CreateUserForm.propTypes = {
  className: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCreateNewTokens: PropTypes.func,
  user: PropTypes.object,
}

CreateUserForm.defaultProps = {
  onSubmit: () => {},
  onCreateNewTokens: () => {},
}

// ----------------------------------------------------------------------------

const StyledCreateUserForm = styled(CreateUserForm)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 50px;
    box-sizing: border-box;

    height: 100%;
    width: 100%;

    > h1 {
      font-weight: bold;
    }

    .form-content-container {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      > h4 {
        padding: 3px 0;

        border-bottom: 1px solid ${(props) => props.theme.UITheme.textLight};
        font-weight: 600;
      }

      .form-switch {
        margin-top: 5px;
      }

      .form-input {
        margin-bottom: 10px;
        width: 100%;
      }
    }

    .btns-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      margin-top: 25px;
      padding: 20px 10px;
      width: 100%;

      background-color: ${(props) => props.theme.UITheme.dividerColor};

      box-sizing: border-box;

      width: 100%;

      .margined-btn {
        margin-right: 10px;
      }

      .disable-btn {
        margin-right: 10px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledCreateUserForm
