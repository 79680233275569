import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {message} from "antd"
import {EditOutlined, SearchOutlined} from '@ant-design/icons'

import * as api from "../../../../api"

import TableStringSearchPopover from "../../../_shared/components/TableStringSearchPopover"

import PageTitleSection from "../../../_shared/components/PageTitleSection"
import Table from "../../../_shared/components/Table"
import roles from "../../../_shared/constants/roles.js"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestTable from "../../../_shared/hooks/useRestTable.js"
import AppButton from "../../../_shared/components/AppButton"
import Drawer from "../../../_shared/components/Drawer"

import CreateCompanyForm from "./CreateCompanyForm"

//------------------------------------------------------------------------------

function Companies(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [{ token, user }] = useStateValue()

  const [
    companiesList,
    fetchCompanies,
    isCompaniesLoading,
    companiesTablePagination,
    totalCompanies,
    tableFiltersDidChange,
    tableFilters,
  ] = useRestTable("/companies", token)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const searchInputRef = useRef()

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedCompany(null)
    }
  }, [isDrawerOpen])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function getPaginationObject() {
    return {
      size: "small",
      total: totalCompanies,
      current: companiesTablePagination.current,
      pageSize: companiesTablePagination.pageSize,
    }
  }

  function handleTableChange(pagination) {
    fetchCompanies({ pagination })
  }

  function openDrawer() {
    setIsDrawerOpen(true)
  }

  function closeDrawer() {
    setIsDrawerOpen(false)
  }

  async function handleCreateCompany({ ...formFields }) {
    const body = {
      ...formFields,
      // role: roles.ADMIN,
      // type: "company",
    }

    return api
      .createCompany(body, token)
      .then(() => {
        fetchCompanies().then(closeDrawer)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  function handleUpdateCompany({ ...formFields }) {
    if (selectedCompany && selectedCompany.id) {
      const body = {
        ...formFields,
        id: selectedCompany.id,
      }

      api
        .updateCompany(body, token)
        .then(() => {
          fetchCompanies().then(closeDrawer)
        })
        .catch((error) => {
          throw new Error(error)
        })
    }
  }

  function handleCreateNewTokens(companyId, number) {
    const body = {
      tokenNumber: number,
    }
    api
      .createNewAdminTokens(companyId, body, token)
      .then(() => {
        fetchCompanies().then(closeDrawer)
      })
      .catch((error) =>
        message.error(
          "Non è stato possibile completare la crezione dei nuovi badge."
        )
      )
  }

  function handleTableRowClick(token) {
    setSelectedCompany(token)

    setIsDrawerOpen(true)
  }

  function handleSearch(filterValue, confirm, searchKey) {
    confirm()

    tableFiltersDidChange({
      ...tableFilters,
      [searchKey]: filterValue,
    })
  }

  function handleReset(filterKey, clearFilters) {
    const newFilters = { ...tableFilters }

    delete newFilters[filterKey]

    tableFiltersDidChange(newFilters)

    clearFilters()
  }

  function getColumnTextSearchProps(searchKey) {
    //eslint-disable
    return {
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <TableStringSearchPopover
            inputRef={searchInputRef}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }}
            onPressEnter={() => {
              handleSearch(selectedKeys[0], confirm, searchKey)
            }}
            onSearch={() => {
              handleSearch(selectedKeys[0], confirm, searchKey)
            }}
            onReset={() => handleReset(searchKey, clearFilters)}
          />
        )
      },
      // eslint-disable-next-line react/display-name
      filterIcon: (filtered) => <SearchOutlined className="filter-icon"/> ,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(
            () =>
              searchInputRef &&
              searchInputRef.current &&
              searchInputRef.current.select(),
            100
          )
        }
      },
    }
    //eslint-enable
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <PageTitleSection title="Aziende" />

      {user.role === roles.SUPER_ADMIN && (
        <div className="table-topbar">
          <AppButton onClick={openDrawer} type="button">
            Crea azienda
          </AppButton>
          <Drawer open={isDrawerOpen} onCancel={closeDrawer}>
            <CreateCompanyForm
              onSubmit={
                selectedCompany ? handleUpdateCompany : handleCreateCompany
              }
              company={(selectedCompany && selectedCompany) || null}
              onCreateNewTokens={handleCreateNewTokens}
            />
          </Drawer>
        </div>
      )}

      <Table
        columns={getColumns(handleTableRowClick, getColumnTextSearchProps)}
        dataSource={companiesList}
        loading={isCompaniesLoading}
        pagination={getPaginationObject()}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleTableRowClick(record),
          }
        }}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Companies.propTypes = {
  className: PropTypes.string.isRequired,
}

Companies.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCompanies = styled(Companies)`
  & {
    .table-topbar {
      margin: 25px 0;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledCompanies

// ----------------------------------------------------------------------------
// Table columns
// ----------------------------------------------------------------------------

function getColumns(handleRowEdit, getColumnTextSearchProps) {
  return [
    {
      title: "ID Azienda",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ragione sociale",
      dataIndex: "companyName",
      key: "companyName",
      ...getColumnTextSearchProps("companyName"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnTextSearchProps("email"),
    },
    {
      title: "Badge utilizzati",
      dataIndex: "assignedTokens",
      key: "assignedTokens",
    },

    {
      title: "Badge disponibili",
      dataIndex: "availableTokens",
      key: "availableTokens",
    },
    {
      title: "",
      dataIndex: "email",
      key: "edit",
      // eslint-disable-next-line
      render: (name, record, index) => {
        return (
          <a>
            <EditOutlined onClick={() => handleRowEdit(record)} />
          </a>
        )
      },
    },
  ]
}
