import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Calendar } from "antd"
import moment from "moment"

import AppButton from "../AppButton"
import {SearchOutlined} from "@ant-design/icons";

// ----------------------------------------------------------------------------

function TableSearchPopover(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { value, onChange, onSearch, onReset } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <Calendar fullscreen={false} value={moment(value)} onSelect={onChange} />

      <div className="row">
        <AppButton className="search-btn" onClick={onSearch} icon="search">
          <SearchOutlined style={{ color: "#FFFFFF" }}/>
          <span>Cerca</span>
        </AppButton>

        <AppButton onClick={onReset} style="ghosted">
          Reset
        </AppButton>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

TableSearchPopover.propTypes = {
  className: PropTypes.string.isRequired,
  inputRef: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
}

TableSearchPopover.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledTableSearchPopover = styled(TableSearchPopover)`
  & {
    display: flex;
    flex-direction: column;

    padding: 20px;
    width: auto;

    .row {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .search-btn {
      margin-right: 10px;

      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      .anticon {
        margin-right: 5px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledTableSearchPopover
