import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message } from "antd"

import * as api from "../../../../api"

import TableStringSearchPopover from "../../../_shared/components/TableStringSearchPopover"

import PageTitleSection from "../../../_shared/components/PageTitleSection"
import Table from "../../../_shared/components/Table"
import roles from "../../../_shared/constants/roles.js"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestTable from "../../../_shared/hooks/useRestTable.js"
import AppButton from "../../../_shared/components/AppButton"
import Drawer from "../../../_shared/components/Drawer"

import CreateUserForm from "./CreateAdminUserForm"
import {EditOutlined, SearchOutlined} from "@ant-design/icons";

//------------------------------------------------------------------------------

function Users(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [{ token, user }] = useStateValue()

  const [
    usersList,
    fetchUsers,
    isUsersLoading,
    usersTablePagination,
    totalUsers,
    tableFiltersDidChange,
    tableFilters,
  ] = useRestTable("/users", token)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const searchInputRef = useRef()

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedUser(null)
    }
  }, [isDrawerOpen])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function getPaginationObject() {
    return {
      size: "small",
      total: totalUsers,
      current: usersTablePagination.current,
      pageSize: usersTablePagination.pageSize,
    }
  }

  function handleTableChange(pagination) {
    fetchUsers({ pagination })
  }

  function openDrawer() {
    setIsDrawerOpen(true)
  }

  function closeDrawer() {
    setIsDrawerOpen(false)
  }

  async function handleCreateAdminUser({ ...formFields }) {
    const body = {
      ...formFields,
      role: roles.ADMIN,
      type: "company",
    }

    return api
      .createAdminUser(body)
      .then(() => {
        fetchUsers().then(closeDrawer)
      })
      .catch((error) => {
        if (error && error.statusCode === 409) {
          message.error("Esiste già un cliente con questa email.")
        } else {
          if (error.message.indexOf("email") !== -1) {
            message.error("Email non valida")
          } else {
            message.error("Non è stato possibile completare la creazione.")
          }
        }
        throw new Error(error)
      })
  }

  function handleUpdateAdminUser({ ...formFields }) {
    if (selectedUser && selectedUser.id) {
      const body = {
        ...formFields,
        id: selectedUser.id,
      }

      api
        .updateUser(body, token)
        .then(() => {
          fetchUsers().then(closeDrawer)
        })
        .catch((error) => {
          if (error && error.statusCode === 409) {
            message.error("Esiste già un cliente con questa email.")
          } else {
            if (error.message.indexOf("email") !== -1) {
              message.error("Email non valida")
            } else {
              message.error("Non è stato possibile completare la creazione.")
            }
          }
          throw new Error(error)
        })
    }
  }

  function handleTableRowClick(token) {
    setSelectedUser(token)

    setIsDrawerOpen(true)
  }

  function handleSearch(filterValue, confirm, searchKey) {
    confirm()

    tableFiltersDidChange({
      ...tableFilters,
      [searchKey]: filterValue,
    })
  }

  function handleReset(filterKey, clearFilters) {
    const newFilters = { ...tableFilters }

    delete newFilters[filterKey]

    tableFiltersDidChange(newFilters)

    clearFilters()
  }

  function getColumnTextSearchProps(searchKey) {
    //eslint-disable
    return {
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <TableStringSearchPopover
            inputRef={searchInputRef}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }}
            onPressEnter={() => {
              handleSearch(selectedKeys[0], confirm, searchKey)
            }}
            onSearch={() => {
              handleSearch(selectedKeys[0], confirm, searchKey)
            }}
            onReset={() => handleReset(searchKey, clearFilters)}
          />
        )
      },
      // eslint-disable-next-line react/display-name
      filterIcon: (filtered) => <SearchOutlined  className="filter-icon" />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(
            () =>
              searchInputRef &&
              searchInputRef.current &&
              searchInputRef.current.select(),
            100
          )
        }
      },
    }
    //eslint-enable
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <PageTitleSection title="Utenti" />

      {user.role === roles.SUPER_ADMIN && (
        <div className="table-topbar">
          <AppButton onClick={openDrawer} type="button">
            Crea utente
          </AppButton>
          <Drawer open={isDrawerOpen} onCancel={closeDrawer}>
            <CreateUserForm
              onSubmit={
                selectedUser ? handleUpdateAdminUser : handleCreateAdminUser
              }
              user={(selectedUser && selectedUser) || null}
            />
          </Drawer>
        </div>
      )}

      <Table
        columns={getColumns(handleTableRowClick, getColumnTextSearchProps)}
        dataSource={usersList}
        loading={isUsersLoading}
        pagination={getPaginationObject()}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleTableRowClick(record),
          }
        }}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Users.propTypes = {
  className: PropTypes.string.isRequired,
}

Users.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledUsers = styled(Users)`
  & {
    .table-topbar {
      margin: 25px 0;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledUsers

// ----------------------------------------------------------------------------
// Table columns
// ----------------------------------------------------------------------------

function getColumns(handleRowEdit, getColumnTextSearchProps) {
  return [
    {
      title: "ID Cliente",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
          return `${record.name || ""} ${record.surname || ""}`
      },
      ...getColumnTextSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnTextSearchProps("email"),
    },
    {
      title: "Azienda",
      dataIndex: "company",
      key: "company",
      render: (name, record) => {
        return record.company?.companyName
      },
    },
    {
      title: "",
      dataIndex: "email",
      key: "edit",
      // eslint-disable-next-line
      render: (name, record, index) => {
        return (
          <a>
            <EditOutlined onClick={() => handleRowEdit(record)} />
          </a>
        )
      },
    },
  ]
}
